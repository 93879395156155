<template>
  <div class="vg_wrapper" v-loading="loadingFlag">
    <div class="vd_edhea vg_button_group">
      <editHeader
        :isShow="isShow"
        :btn="btn"
        :strForm="sourForm"
        @openEdit="openEdit"
        @closeEdit="closeEdit"
        @submit="submit('sourForm')"
      />
      <el-button type="primary" size="small" class="vd_export" @click="buttonRefresh()" v-if="isShow">刷新</el-button>
    </div>
    <el-form ref="sourForm" :model="sourForm" :rules="rules" label-width="120px" size="mini" :disabled="isShow">
      <el-row class="vg_mb_16">
        <el-col :md="8">
          <el-form-item label="申请单号" prop="sour_no">
            <el-input
              maxlength="10"
              v-model="sourForm.sour_no"
              @input="sourForm.sour_no = helper.keepEngNum2(sourForm.sour_no)"
              placeholder="请填写申请单号"
              show-word-limit
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="公司编号" prop="cptt_id">
            <el-select v-model="sourForm.cptt_id" placeholder="请选择公司编号" @change="cpttChange" size="small" clearable>
              <el-option v-for="item in cpttList" :key="item.cptt_id" :label="item.cptt_no" :value="item.cptt_id"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="总金额" prop="sour_price">
            <el-input
              maxlength="10"
              v-model="sourForm.sour_price"
              @blur="sourForm.sour_price = helper.calcPriceOne(sourForm.sour_price, 4, 1000000)"
              @input="sourForm.sour_price = helper.keepTNum1(sourForm.sour_price)"
              placeholder="将根据额外金额和物料价格自动生成"
              show-word-limit
              :disabled="true"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_16">
        <el-col :md="8">
          <el-form-item label="交货地址" prop="cptt_address">
            <el-input maxlength="50" v-model="sourForm.cptt_address" type="textarea" rows="1" placeholder="请填写地址" show-word-limit>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="交货时间" prop="sour_deli_time">
            <el-date-picker v-model="sourForm.sour_deli_time" type="date" placeholder="请选择时间日期"> </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="额外金额" prop="extra_price">
            <el-input
              v-model="sourForm.extra_price"
              maxlength="10"
              placeholder="请填写额外金额"
              show-word-limit
              @change="extraPrice"
              @blur="sourForm.extra_price = helper.haveFour(sourForm.extra_price)"
              @input="sourForm.extra_price = helper.keepTNum1(sourForm.extra_price)"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="备注">
            <el-input maxlength="200" v-model="sourForm.remark" type="textarea" rows="1" placeholder="请填写备注" show-word-limit>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="vd_solidDiv"></div>
      <div>
        <div class="vd_but">
          <!-- <el-button type="primary" plain size="small" @click="addRow()">新增</el-button> -->
          <el-button type="success" plain size="small" @click="smplImport()">导入</el-button>
          <el-button type="danger" plain size="small" @click="delBom()">删除</el-button>
        </div>
        <SourSmplList
          @sourPrice="sourPrice"
          :mtrlTypeGroupOpt="mtrlTypeGroupOpt"
          :sourForm="sourForm"
          :stffType="stffType"
          @handleSelectionChange="handleSelectionChange"
        ></SourSmplList>
      </div>
      <el-dialog :title="title" :visible.sync="smplFlag" width="70%">
        <SourSmplResult @falseBom="falseBom" @childSmpl="childSmpl" @childSmplChanel="childSmplChanel" ref="SmplEditIn"></SourSmplResult>
      </el-dialog>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="false" :stffForm="stffForm" ref="userData" v-if="sourForm.stff_name"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { sourAPI } from '@api/modules/sour';
import { stffAPI } from '@api/modules/staff';
import { cpttAPI } from '@api/modules/cptt';
import { optnAPI } from '@api/modules/optn';
import editHeader from '@/views/component/editHeader';
import inputUser from '@/views/component/inputUser';
import SourSmplList from '@/views/PurchaseManagement/SourManage/TabChild/Componet/SourSmplList.vue';
import SourSmplResult from '@/views/PurchaseManagement/SourManage/TabChild/Componet/SourSmplResult.vue';

export default {
  name: 'sourEditMain',
  components: {
    editHeader,
    inputUser,
    SourSmplList,
    SourSmplResult
  },
  data() {
    var validatePrice = (rule, value, callback) => {
      if (value > 999999.9999) {
        callback(new Error('总金额不能大于999999.9999'));
      }
      callback();
    };
    return {
      rules: {
        sour_price: [{ validator: validatePrice }],
        sour_no: [{ required: true, trigger: 'blur', message: ' ' }],
        cptt_id: [{ required: true, trigger: 'change', message: ' ' }],
        cptt_address: [{ required: true, trigger: 'change', message: ' ' }],
        sour_deli_time: [{ required: true, trigger: 'change', message: ' ' }],
        extra_price: [
          {
            pattern: /^\d{0,4}(\.\d{1,4})?$/,
            message: '最大只能输入9999.9999并且只能输入数字且最多4位小数!'
          }
        ]
      },
      sourForm: {},
      isShow: true,
      btn: {},
      stffForm: {},
      stffType: [],
      sourCopy: [],
      selectChangeList: [],
      mtrlTypeGroupOpt: [],
      cpttList: [],
      smplFlag: false,
      title: '物料信息',
      totalPrice: null,
      loadingFlag: true
    };
  },
  created() {
    this.initData();
  },
  watch: {},
  methods: {
    initData() {
      this.getsourInfo();
      this.getStffType();
      this.getCpttV1();
      this.getMtrlType();
      this.loadingFlag = true;
    },
    //启用编辑
    openEdit() {
      this.isShow = false;
      this.$emit('isShow', this.isShow);
    },
    //撤销编辑
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.$emit('isShow', this.isShow);
          this.initData();
          this.$message({
            type: 'info',
            message: '已撤销编辑!'
          });
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //提交表单
    submit(formName) {
      this.$confirm('确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate(valid => {
            if (valid) {
              this.saveInfo();
            } else {
              console.log('error submit!');
              return false;
            }
          });
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 保存
    saveInfo() {
      let sourForm = {};
      sourForm = Object.assign(sourForm, this.sourForm);
      sourForm.sour_deli_time = parseInt(Number(sourForm.sour_deli_time) / 1000);
      this.sourCopy = this.sourCopy.filter(item => sourForm.sour_mtrl_list.every(item1 => item.sour_mtrl_id !== item1.sour_mtrl_id));
      for (let i = 0; i < this.sourCopy.length; i++) {
        this.sourCopy[i].delete_time = parseInt(new Date().getTime() / 1000);
      }
      sourForm.sour_mtrl_list = sourForm.sour_mtrl_list.concat(this.sourCopy);
      for (let i = 0; i < sourForm.sour_mtrl_list.length; i++) {
        sourForm.sour_mtrl_list[i].smpl_deil_time = parseInt(sourForm.sour_mtrl_list[i].smpl_deil_time / 1000);
      }

      console.log(111, sourForm);
      post(sourAPI.editSour, sourForm).then(res => {
        if (res.data.code === 0) {
          this.$message({
            type: 'success',
            message: '保存成功'
          });
          this.isShow = true;
          this.$emit('isShow', this.isShow);
          this.initData();
        } else if (res.data.code === 7) {
          this.$message({
            type: 'error',
            message: '申请单号重复'
          });
          sourForm.sour_deli_time = Number(sourForm.sour_deli_time) * 1000;
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
          this.initData();
          sourForm.sour_deli_time = Number(sourForm.sour_deli_time) * 1000;
        }
      });
    },
    //取消
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.resetForm('sourForm');
          this.$router.go(-1);
          this.$message({
            type: 'info',
            message: '已取消新增!'
          });
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 计算价格
    calcPriceOne(num, posi = 0, str) {
      let num1 = 0;
      let fre = Math.pow(10, posi);
      num1 = Math.floor(num * fre) / fre;
      if (isNaN(num1)) {
        return 0;
      } else {
        if (num1 >= str) {
          this.$message({
            type: 'error',
            message: '总金额不能大于1000000'
          });
          return '';
        } else {
          let value = num1;
          value = value.toString();
          if (value) {
            let ind = value.toString().indexOf('.');
            if (ind < 0) {
              return value + '.0000';
            } else {
              if (value.length - 1 - ind > 4) {
                return value.splice(ind + 5);
              } else if (value.length - 1 - ind < 4) {
                let index = 4 - (value.length - 1 - ind);
                for (let i = 0; i < index; i++) {
                  value = value + '0';
                }
                return value;
              } else {
                return value;
              }
            }
          }
        }
      }
    },
    // 刷新按钮
    buttonRefresh() {
      this.initData();
    },
    // cptt变化选择地址编号
    cpttChange(val) {
      let temp = {};
      temp = this.cpttList.filter(item => item.cptt_id === val);
      if (temp.length === 1) {
        this.sourForm.cptt_address = temp[0].cptt_address;
        this.sourForm.cptt_no = temp[0].cptt_no;
      }
    },
    // 打开弹框
    smplImport() {
      this.smplFlag = true;
    },
    // 导入传值
    childSmpl(val) {
      let value = val;
      value = value.filter(item => this.sourForm.sour_mtrl_list.every(item1 => item.mtrl_id !== item1.mtrl_id));
      for (let i = 0; i < value.length; i++) {
        if (value[i].mtrl_width || value[i].mtrl_width === 0) {
          value[i].mtrl_width = this.helper.haveFour(value[i].mtrl_width);
        }
        if (value[i].mtrl_prod_price || value[i].mtrl_prod_price === 0) {
          value[i].mtrl_prod_price = this.helper.haveFour(value[i].mtrl_prod_price);
        }
        value[i].disabledFlag = true;
      }
      this.sourForm.sour_mtrl_list = this.sourForm.sour_mtrl_list.concat(value);
      this.$refs.SmplEditIn.clear();
      this.smplFlag = false;
    },
    //新增采购
    addRow() {
      let item = {
        supp_name: null,
        mtrl_no: null,
        mtrl_name: null,
        mtrl_type: null,
        mtrl_spec: null,
        mtrl_color: null,
        mtrl_width: null,
        mtrl_unit: null,
        mtrl_prod_price: null,
        mtrl_retail_price: null,
        mtrl_num: null,
        disabledFlag: false,
        addFlag: true
      };
      this.sourForm.sour_mtrl_list.push(item);
    },
    // 取消选择
    childSmplChanel() {
      this.smplFlag = false;
    },
    falseBom() {
      this.childSmplChanel();
    },
    // 样品list清单多选删除
    delBom() {
      if (this.selectChangeList.length > 0) {
        this.sourForm.sour_mtrl_list = this.sourForm.sour_mtrl_list.filter(item =>
          this.selectChangeList.every(item1 => item.mtrl_id !== item1.mtrl_id)
        );
        let price = 0.0;
        let mtrlList = this.sourForm.sour_mtrl_list;
        for (let i = 0; i < mtrlList.length; i++) {
          if (mtrlList[i].mtrl_num) {
            price +=
              (parseInt(Number(mtrlList[i].mtrl_retail_price) * 100000) * parseInt(Number(mtrlList[i].mtrl_num) * 100000)) /
              100000 /
              100000;
          }
        }
        let price1 = parseInt(Number(price) * 1000000);
        let price2 = parseInt(Number(this.sourForm.extra_price) * 1000000);
        this.sourForm.sour_price = this.helper.haveFour((Number(price1) + Number(price2)) / 1000000);
        if (this.sourForm.sour_mtrl_list.length === 0) {
          this.totalPrice = 0;
        }
      } else {
        this.$message({
          message: '至少选择一条信息',
          type: 'warning'
        });
      }
    },
    // 样品单list选择
    handleSelectionChange(val) {
      this.selectChangeList = val;
    },
    // 更改价格
    sourPrice(val, val2) {
      // if (val < 1000000) {
      let price1 = parseInt(Number(val) * 1000000);
      let price2 = parseInt(Number(this.sourForm.extra_price) * 1000000);
      this.sourForm.sour_price = this.helper.haveFour((Number(price1) + Number(price2)) / 1000000);
      this.totalPrice = JSON.parse(JSON.stringify(val));
      // } else {
      //   this.sourForm.sour_price = null;
      //   setTimeout(() => {
      //     this.$message({
      //       type: "error",
      //       message: "总金额不能大于1000000",
      //     });
      //   }, 1000);
      // }
    },
    // 获取委托人
    getStffType() {
      get(stffAPI.getAllStffsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.stffType = res.data.data;
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 获取公司信息
    getCpttV1() {
      get(cpttAPI.getAllCpttsV2)
        .then(res => {
          if (res.data.code === 0) {
            this.cpttList = res.data.data;
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取单位
    getMtrlType() {
      get(optnAPI.getAllContent, { perm_id: 10005 })
        .then(res => {
          if (res.data.code === 0) {
            this.mtrlTypeGroupOpt = res.data.data;
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取表单信息
    getsourInfo() {
      this.sourForm.sour_id = this.$route.query.form_id;
      get(sourAPI.getSourById, { sour_id: this.sourForm.sour_id })
        .then(res => {
          if (res.data.code === 0) {
            this.sourForm = res.data.data.form;
            this.sourForm.sour_deli_time = Number(this.sourForm.sour_deli_time) * 1000;
            this.sourCopy = JSON.parse(JSON.stringify(this.sourForm.sour_mtrl_list));
            // this.sourForm.sour_price = this.helper.haveFour(
            //   this.sourForm.sour_price
            // );
            this.btn = res.data.data.btn;
            this.sourForm.sour_price = this.helper.haveFour(this.sourForm.sour_price);
            this.sourForm.extra_price = this.helper.haveFour(this.sourForm.extra_price);
            for (let i = 0; i < this.sourForm.sour_mtrl_list.length; i++) {
              if (this.sourForm.sour_mtrl_list[i].mtrl_width !== null) {
                this.sourForm.sour_mtrl_list[i].mtrl_width = this.helper.haveFour(this.sourForm.sour_mtrl_list[i].mtrl_width);
              }
              if (this.sourForm.sour_mtrl_list[i].mtrl_prod_price !== null) {
                this.sourForm.sour_mtrl_list[i].mtrl_prod_price = this.helper.haveFour(this.sourForm.sour_mtrl_list[i].mtrl_prod_price);
              }
              if (this.sourForm.sour_mtrl_list[i].mtrl_retail_price !== null) {
                this.sourForm.sour_mtrl_list[i].mtrl_retail_price = this.helper.haveFour(this.sourForm.sour_mtrl_list[i].mtrl_retail_price);
              }
              if (this.sourForm.sour_mtrl_list[i].mtrl_num !== null) {
                this.sourForm.sour_mtrl_list[i].mtrl_num = this.helper.haveFour(this.sourForm.sour_mtrl_list[i].mtrl_num);
              }
              this.sourForm.sour_mtrl_list[i].disabledFlag = true;
            }
            this.stffForm.stff_name = this.sourForm.stff_name;
            this.stffForm.dept_name = this.sourForm.dept_name;
            this.stffForm.dept_team_name = this.sourForm.dept_team_name;
            this.stffForm.user_id = this.sourForm.user_id;
            this.stffForm.dept_id = this.sourForm.dept_id;
            this.stffForm.stff_id = this.sourForm.stff_id;
            this.totalPrice = res.data.data.form.sour_price - res.data.data.form.extra_price;
            setTimeout(() => {
              this.loadingFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    extraPrice() {
      if (this.sourForm.extra_price) {
        if (this.sourForm.extra_price == 0) {
          this.sourForm.sour_price = this.helper.haveFour(this.totalPrice);
          return;
        }
        let price1 = parseInt(Number(this.totalPrice) * 1000000);
        let price2 = parseInt(Number(this.sourForm.extra_price) * 1000000);
        this.sourForm.sour_price = this.helper.haveFour((Number(price1) + Number(price2)) / 1000000);
      } else {
        this.sourForm.sour_price = this.helper.haveFour(this.totalPrice);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader ::v-deep .el-upload--text {
  width: 136px;
  height: 136px;
}
.avatar-uploader .el-upload:hover {
  border-color: $color-primary;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: $color-uploader-icon;
  width: 136px;
  height: 136px;
  line-height: 136px;
  text-align: center;
}
.avatar {
  width: 136px;
  height: 136px;
  display: block;
}
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}
.vd_mrt1 {
  margin-top: 1px;
}
.vd_edhea {
  position: relative;
}
.vd_export {
  position: absolute;
  top: 0;
  left: 65px;
}
.vd_solidDiv {
  height: 1px;
  border: none;
  background-color: #dcdfe6;
  margin: 10px 0 20px 0;
}
.vd_but {
  margin-bottom: 10px;
}
.el-row {
  margin-top: 2px;
}
</style>
